<template>
    <div>
        <v-container dense fluid class="px-0">
            <v-card outlined>
                <v-row>
                    <v-col cols="3">
                        <v-card color="#385F73" dark>
                            <v-card-title class="text-h6">
                                Total All
                            </v-card-title>

                            <div class="stock-container">
                                <template v-for="(item, index) in MAT_CODE">
                                    <v-card-text
                                        :key="index"
                                        class="d-flex justify-space-between align-center py-0"
                                    >
                                        <p class="text-caption ma-0">
                                            {{ index }}
                                        </p>
                                        <p
                                            class="ma-0 font-weight-bold white--text"
                                        >
                                            {{ item.all }}
                                        </p>
                                    </v-card-text>
                                </template>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="3">
                        <v-card color="#1F7087" dark>
                            <v-card-title class="text-h6">
                                Stock in MC
                            </v-card-title>

                            <div class="stock-container">
                                <template v-for="(item, index) in MAT_CODE">
                                    <v-card-text
                                        :key="index"
                                        class="d-flex justify-space-between align-center py-0"
                                    >
                                        <p class="text-caption ma-0">
                                            {{ index }}
                                        </p>
                                        <p
                                            class="ma-0 font-weight-bold white--text"
                                        >
                                            {{ item.mc }}
                                        </p>
                                    </v-card-text>
                                </template>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="3">
                        <v-card color="#358da6" dark>
                            <v-card-title class="text-h6">
                                Stock in IC
                            </v-card-title>

                            <div class="stock-container">
                                <template v-for="(item, index) in MAT_CODE">
                                    <v-card-text
                                        :key="index"
                                        class="d-flex justify-space-between align-center py-0"
                                    >
                                        <p class="text-caption ma-0">
                                            {{ index }}
                                        </p>
                                        <p
                                            class="ma-0 font-weight-bold white--text"
                                        >
                                            {{ item.ic }}
                                        </p>
                                    </v-card-text>
                                </template>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col cols="3">
                        <v-card color="#45a8c4" dark>
                            <v-card-title class="text-h6">
                                Stock in Btl/Slv/Box
                            </v-card-title>

                            <div class="stock-container">
                                <template v-for="(item, index) in MAT_CODE">
                                    <v-card-text
                                        :key="index"
                                        class="d-flex justify-space-between align-center py-0"
                                    >
                                        <p class="text-caption ma-0">
                                            {{ index }}
                                        </p>
                                        <p
                                            class="ma-0 font-weight-bold white--text"
                                        >
                                            {{ item.pckg }}
                                        </p>
                                    </v-card-text>
                                </template>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

            <v-card elevation="3">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :search="search"
                        :items="dataStock"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                        dense
                    >
                        <template v-slot:top>
                            <v-toolbar flat dense class="mb-5">
                                <v-row dense>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="tujuanGudang"
                                            label="Gudang"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listGudangTujuan"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="getBatch"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="batch"
                                            label="Batch"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listBatch"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="getDatas"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0">
                                        <download-csv
                                            :data="dataJsonToCsv"
                                            name="Laporan Stock.csv"
                                        >
                                            <v-btn
                                                color="#61B15A"
                                                dark
                                                class="mb-2 text-subtitle-1"
                                                style="text-transform: unset !important"
                                            >
                                                <v-icon left
                                                    >mdi-file-download</v-icon
                                                >
                                                Unduh Data
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <v-toolbar flat dense class="mb-5">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    autocomple="off"
                                    placeholder="Search..."
                                ></v-text-field>
                            </v-toolbar>
                            <!-- <div class="d-flex mb-5 px-4 justify-space-between align-center">
                <v-row>
                  <v-col cols="2">
                    <v-select
                      v-model="itemsPerPage"
                      :items="showPerPage"
                      outlined
                      dense
                      label="items per page"
                      v-on:change="changeItemPerPage"
                    >
                    </v-select>
                  </v-col>
                  <v-col align="right">
                    <span class="mr-15 text-caption">Page: {{ page }}</span>
                    <v-btn
                      fab
                      x-small
                      depressed
                      color="primary"
                      class="mx-3"
                      @click="prevPage"
                    >
                      <v-icon dark> mdi-chevron-left </v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      x-small
                      depressed
                      color="primary"
                      class="mx-3"
                      @click="nextPage"
                    >
                      <v-icon dark> mdi-chevron-right </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div> -->
                        </template>

                        <template v-slot:item.qtyuom="{ item }">
                            <span>
                                {{ getQtyStock(item) }}
                            </span>
                        </template>
                        <template slot="body.append">
                            <tr v-bind:style="{ background: 'lightgrey' }">
                                <th class="title"></th>
                                <th class="title">Total</th>
                                <th class="title"></th>
                                <th class="title"></th>
                                <th class="title"></th>
                                <th class="title">{{ sumField("qty") }}</th>
                            </tr>
                        </template>
                    </v-data-table>
                    <!-- <div class="d-flex mt-5 px-4 justify-space-between align-center">
            <v-row>
              <v-col cols="2">
                <v-select
                  v-model="itemsPerPage"
                  :items="showPerPage"
                  outlined
                  dense
                  label="items per page"
                  v-on:change="changeItemPerPage"
                >
                </v-select>
              </v-col>
              <v-col align="right">
                <span class="mr-15 text-caption">Page: {{ page }}</span>
                <v-btn
                  fab
                  x-small
                  depressed
                  color="primary"
                  class="mx-3"
                  @click="prevPage"
                >
                  <v-icon dark> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  fab
                  x-small
                  depressed
                  color="primary"
                  class="mx-3"
                  @click="nextPage"
                >
                  <v-icon dark> mdi-chevron-right </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div> -->
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
    data: () => ({
        search: "",
        loading: false,
        listGudangTujuan: [],
        listBatch: [],
        headers: [
            {
                text: "Gudang",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "gudang_name",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "Material Code",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "mat_code",
            },
            {
                text: "UoM",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "satuan",
            },
            {
                text: "Quantity UoM",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qtyuom",
            },
            {
                text: "Quantity BTL/SLV/BOX",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qty",
            },
        ],
        dataStock: [],
        tujuanGudang: "",
        batch: "",
        dataJsonToCsv: [],
        // page: 1,
        // itemsPerPage: 10,
        // showPerPage: [5, 10, 20, 50, 100, 250, 500],
        MAT_CODE: {
            HT10: {
                ic: 0,
                mc: 0,
                pckg: 0,
                all: 0,
            },
            HT30: {
                ic: 0,
                mc: 0,
                pckg: 0,
                all: 0,
            },
            MT10: {
                ic: 0,
                mc: 0,
                pckg: 0,
                all: 0,
            },
            MT30: {
                ic: 0,
                mc: 0,
                pckg: 0,
                all: 0,
            },
        },
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),
        resetMatCode() {
            this.MAT_CODE = {
                HT10: {
                    ic: 0,
                    mc: 0,
                    pckg: 0,
                    all: 0,
                },
                HT30: {
                    ic: 0,
                    mc: 0,
                    pckg: 0,
                    all: 0,
                },
                MT10: {
                    ic: 0,
                    mc: 0,
                    pckg: 0,
                    all: 0,
                },
                MT30: {
                    ic: 0,
                    mc: 0,
                    pckg: 0,
                    all: 0,
                },
            };
        },
        sumField(key) {
            // sum data in give key (property)
            return this.dataStock.reduce(
                (a, b) => Number(a) + (Number(b[key]) || 0),
                0
            );
        },

        applyFilter() {
            this.loading = true;

            axios
                .get(
                    "v1/admin/dashboard/stock?gudang_id=" +
                        this.tujuanGudang +
                        "&batch=" +
                        this.batch
                )
                .then((response) => {
                    this.loading = false;
                    this.resetMatCode();
                    this.dataStock = response.data.data;
                    response.data.data.forEach((v) => {
                        const { is_meta, mat_code, satuan, qty } = v;
                        let type = "pckg";
                        let divider = this.getItemStockDivider(
                            satuan,
                            mat_code,
                            is_meta
                        );

                        if (satuan == "MC") type = "mc";
                        if (satuan == "IC") type = "ic";

                        if (mat_code && type) {
                            let total = Number(qty) / divider;
                            total = this.MAT_CODE[mat_code][type] + total;

                            const commas = total % 1;
                            total = commas ? Number(total.toFixed(2)) : total;

                            this.MAT_CODE[mat_code][type] = total;
                            this.MAT_CODE[mat_code].all += Number(qty);
                        }
                    });

                    this.unduhData();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.applyFilter();
                        }
                    }
                });
        },
        getDatas() {
            //   this.getLoadingDate();
            this.applyFilter();
        },
        getGudang() {
            this.getGudangTujuan();
            //   this.getGudangAsal();
        },
        getGudangTujuan() {
            const defaultSelect = { id: "", name: "semua gudang" };
            this.listGudangTujuan.push(defaultSelect);
            axios.get("v1/user/gudang/list?limit=100").then((response) => {
                response.data.data.forEach((v) => {
                    this.listGudangTujuan.push(v);
                });
            });
        },
        getBatch() {
            const defaultSelect = { id: "", name: "semua batch" };
            this.listBatch.push(defaultSelect);
            axios
                .get(
                    "v1/admin/dashboard/stock/listBatch?gudang_id=" +
                        this.tujuanGudang
                )
                .then((response) => {
                    if (response.data.data) {
                        response.data.data.forEach((v) => {
                            this.listBatch.push(v.batch_no);
                        });
                    }
                    this.applyFilter();
                });
        },

        changeItemPerPage() {
            this.applyFilter();
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.applyFilter();
            }
        },
        nextPage() {
            if (this.dataBarcode === null) {
                return;
            } else {
                this.page = this.page + 1;
                this.applyFilter();
            }
        },
        next(page) {
            this.page = page;
            this.applyFilter();
        },
        unduhData() {
            axios
                .get(
                    "v1/admin/dashboard/stock?gudang_id=" +
                        this.tujuanGudang +
                        "&batch=" +
                        this.batch +
                        "&download=1"
                )
                .then((response) => {
                    var parseData = this.$papa.parse(response.data, {
                        header: true,
                    });
                    this.dataJsonToCsv = parseData.data;
                });
        },
    },
    created() {
        this.getGudang();
        this.getBatch();
        // this.getLoadingDate();
    },
};
</script>

<style lang="css">
.stock-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: -1rem;
    padding-bottom: 1rem;
}
</style>
