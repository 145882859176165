<template>
  <div>
    <sidebar :id="id" :menu="menu" :submenu="submenu" />
    <v-container fluid>
      <Data />
      <Stock />
      <!-- <Chart /> -->
      <!-- <grafik /> -->
    </v-container>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Data from '@/components/Dashboard/Data.vue';
import Stock from '@/components/Dashboard/stock.vue';
// import Chart from '@/components/Dashboard/Chart.vue';
// import Grafik from '../components/Dashboard/Grafik.vue';

export default {
  // components: { Sidebar, Data, Chart, Grafik },
  components: { Sidebar, Data, Stock },

  name: "Dashboard",

  data: () => ({
    id: "99",
    menu: "Dashboard",
    submenu: "",
  }),

  metaInfo: {
    title: "Rhea Admin",
    titleTemplate: "%s - Dashboard",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },
};
</script>