<template>
    <v-row>
        <v-col class="ma-auto">
            <v-card>
                <v-card-title>
                    <v-icon left size="24" color="#185ADB"
                        >mdi-inbox-full</v-icon
                    >
                    <span class="text-subtitle-1 font-weight-bold black--text">
                        {{ totalStock }}
                    </span>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-list-item>
                        <v-list-item-content>
                            <v-card-title
                                class="pa-0 text-subtitle-2 font-weight-bold black--text"
                            >
                                Total Stock
                            </v-card-title>
                            <v-card-title
                                class="pa-0 text-caption grey--text"
                                style="line-height: normal"
                            >
                                Stock Barang
                            </v-card-title>
                        </v-list-item-content>
                        <v-list-item-avatar class="ml-3">
                            <v-icon size="16.67" color="#A7A7A7"
                                >mdi-chevron-right-circle</v-icon
                            >
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col class="ma-auto">
            <v-card>
                <v-card-title>
                    <v-icon left size="24" color="#D4AB2D"
                        >mdi-account-group</v-icon
                    >
                    <span class="text-subtitle-1 font-weight-bold black--text">
                        {{ totalUser }}
                    </span>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-list-item>
                        <v-list-item-content>
                            <v-card-title
                                class="pa-0 text-subtitle-2 font-weight-bold black--text"
                            >
                                Total User
                            </v-card-title>
                            <v-card-title
                                class="pa-0 text-caption grey--text"
                                style="line-height: normal"
                            >
                                Jumlah Pengguna
                            </v-card-title>
                        </v-list-item-content>
                        <v-list-item-avatar class="ml-3">
                            <v-icon size="16.67" color="#A7A7A7"
                                >mdi-chevron-right-circle</v-icon
                            >
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col class="ma-auto">
            <v-card>
                <v-card-title>
                    <v-icon left size="24" color="#F99886">mdi-emoticon</v-icon>
                    <span class="text-subtitle-1 font-weight-bold black--text">
                        {{ totalCustomer }}
                    </span>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-list-item>
                        <v-list-item-content>
                            <v-card-title
                                class="pa-0 text-subtitle-2 font-weight-bold black--text"
                            >
                                Total Customer
                            </v-card-title>
                            <v-card-title
                                class="pa-0 text-caption grey--text"
                                style="line-height: normal"
                            >
                                Jumlah Pelanggan
                            </v-card-title>
                        </v-list-item-content>
                        <v-list-item-avatar class="ml-3">
                            <v-icon size="16.67" color="#A7A7A7"
                                >mdi-chevron-right-circle</v-icon
                            >
                        </v-list-item-avatar>
                    </v-list-item>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
    data: () => ({
        totalStock: "0",
        totalUser: "0",
        totalCustomer: "0",
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        getStock() {
            axios
                .get("v1/admin/dashboard/total_stock")
                .then((response) => (this.totalStock = response.data.data || 0))
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getStock();
                        }
                        console.clear();
                    }
                });
        },
        getUserWeb() {
            axios
                .get("v1/admin/dashboard/total_uweb")
                .then((response) => (this.totalUser = response.data.data || 0))
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getUserWeb();
                        }
                        console.clear();
                    }
                });
        },
        getCustomer() {
            axios
                .get("v1/admin/dashboard/total_cust")
                .then((response) => {
                    this.totalCustomer = response.data.data || 0;
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getCustomer();
                        }
                        console.clear();
                    }
                });
        },
    },

    created() {
        this.getStock();
        this.getUserWeb();
        this.getCustomer();
    },
};
</script>
